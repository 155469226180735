@charset "UTF-8";

@font-face {
  font-family: 'rxnt-font';
  src: url('fonts/rxnt-font.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'rxnt-font' !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  vertical-align: text-top;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'rxnt-font' !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-corporate:before {
  content: '\e000';
}
.icon-scheduling:before {
  content: '\e001';
}
.icon-visibility-off:before {
  content: '\e002';
}
.icon-search:before {
  content: '\e003';
}
.icon-visibility:before {
  content: '\e004';
}
.icon-more-horizontal:before {
  content: '\e005';
}
.icon-more-vertical:before {
  content: '\e006';
}
.icon-utilities:before {
  content: '\e007';
}
.icon-billing:before {
  content: '\e008';
}
.icon-dashboard:before {
  content: '\e009';
}
.icon-chevron-up:before {
  content: '\e00a';
}
.icon-calendar:before {
  content: '\e00b';
}
.icon-bank-account:before {
  content: '\e00c';
}
.icon-invoice:before {
  content: '\e00d';
}
.icon-info-outline:before {
  content: '\e00e';
}
.icon-fax:before {
  content: '\e00f';
}
.icon-error-outline:before {
  content: '\e010';
}
.icon-duplicate:before {
  content: '\e011';
}
.icon-delete:before {
  content: '\e012';
}
.icon-date-range:before {
  content: '\e013';
}
.icon-credit-card:before {
  content: '\e014';
}
.icon-clear-filter:before {
  content: '\e015';
}
.icon-payment-method:before {
  content: '\e016';
}
.icon-purchase-history:before {
  content: '\e017';
}
.icon-save:before {
  content: '\e018';
}
.icon-success-outline:before {
  content: '\e019';
}
.icon-warning-outline:before {
  content: '\e01a';
}
.icon-license:before {
  content: '\e01b';
}
.icon-ehr:before {
  content: '\e01c';
}
.icon-error-filled:before {
  content: '\e01d';
}
.icon-info-filled:before {
  content: '\e01e';
}
.icon-warning-filled:before {
  content: '\e01f';
}
.icon-success-filled:before {
  content: '\e020';
}
.icon-close:before {
  content: '\e021';
}
.icon-column-filled:before {
  content: '\e022';
}
.icon-clear-filter-filled:before {
  content: '\e023';
}
.icon-filter-filled:before {
  content: '\e024';
}
.icon-heart-filled:before {
  content: '\e025';
}
.icon-checkbox-indeterminate:before {
  content: '\e026';
}
.icon-checkbox-unchecked:before {
  content: '\e027';
}
.icon-radiobutton-selected:before {
  content: '\e028';
}
.icon-radiobutton-unselected:before {
  content: '\e029';
}
.icon-checkbox-checked:before {
  content: '\e02a';
}
.icon-radiobutton-unselected-disabled:before {
  content: '\e02b';
}
.icon-erx:before {
  content: '\e02d';
}
.icon-filter:before {
  content: '\e02e';
}
.icon-checkbox-unselected-disabled:before {
  content: '\e02c';
}
